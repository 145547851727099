import * as React from "react"
import Svg, { G, Rect, Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width="100%"
      height="100%"
      viewBox="0 0 1449 473"
      xmlSpace="preserve"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        strokeLinejoin: "round",
        strokeMiterlimit: 2,
      }}
      {...props}
      onErr
    >
      <G id="Artboard1" transform="matrix(1,0,0,1,-94.3349,-94.4481)">
        <Rect
          x={94.335}
          y={94.448}
          width={1448.55}
          height={472.472}
          style={{
            fill: "none",
          }}
        />
        <G
          id="warstwa-1"
          transform="matrix(4.16667,0,0,4.16667,0.334857,0.448066)"
        >
          <G transform="matrix(-1,0,0,1,593.158,-2.84217e-14)">
            <Rect
              x={245.561}
              y={45.354}
              width={102.036}
              height={68.025}
              style={{
                fill: "rgb(7,78,162)",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,294.404,53.4977)">
            <Path
              d="M0,6.683L2.158,5.11L4.309,6.683L3.487,4.142L5.676,2.571L2.98,2.571L2.158,0L1.335,2.571L-1.369,2.571L0.814,4.142L0,6.683Z"
              style={{
                fill: "rgb(255,242,0)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,283.26,56.4803)">
            <Path
              d="M0,6.69L2.158,5.117L4.309,6.69L3.488,4.151L5.676,2.578L2.98,2.578L2.153,0L1.33,2.585L-1.36,2.578L0.823,4.151L0,6.69Z"
              style={{
                fill: "rgb(255,242,0)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,277.259,71.3429)">
            <Path
              d="M0,-6.687L-0.823,-4.107L-3.518,-4.113L-1.335,-2.54L-2.151,0L0,-1.567L2.158,0L1.337,-2.54L3.527,-4.113L0.829,-4.113L0,-6.687Z"
              style={{
                fill: "rgb(255,242,0)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,274.275,77.361)">
            <Path
              d="M0,3.53L2.144,5.11L1.328,2.569L3.518,0.998L0.822,0.998L0,-1.58L-0.836,1.004L-3.527,0.998L-1.344,2.569L-2.158,5.11L0,3.53Z"
              style={{
                fill: "rgb(255,242,0)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,278.088,91.0588)">
            <Path
              d="M0,-1.541L-0.829,-4.112L-1.65,-1.541L-4.347,-1.541L-2.164,0.038L-2.98,2.571L-0.829,0.998L1.329,2.571L0.508,0.038L2.697,-1.541L0,-1.541Z"
              style={{
                fill: "rgb(255,242,0)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,286.252,99.2278)">
            <Path
              d="M0,-1.541L-0.823,-4.112L-1.652,-1.534L-4.348,-1.541L-2.158,0.032L-2.974,2.571L-0.823,0.998L1.335,2.571L0.514,0.032L2.704,-1.541L0,-1.541Z"
              style={{
                fill: "rgb(255,242,0)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,297.383,102.18)">
            <Path
              d="M0,-1.534L-0.822,-4.112L-1.645,-1.534L-4.347,-1.534L-2.158,0.034L-2.98,2.578L-0.822,1.005L1.335,2.578L0.506,0.034L2.704,-1.534L0,-1.534Z"
              style={{
                fill: "rgb(255,242,0)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,308.514,99.2278)">
            <Path
              d="M0,-1.541L-0.829,-4.112L-1.659,-1.534L-4.342,-1.541L-2.164,0.032L-2.98,2.571L-0.829,0.998L1.329,2.571L0.506,0.032L2.704,-1.541L0,-1.541Z"
              style={{
                fill: "rgb(255,242,0)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,316.685,91.0588)">
            <Path
              d="M0,-1.541L-0.829,-4.112L-1.65,-1.541L-4.342,-1.541L-2.158,0.038L-2.987,2.571L-0.829,0.998L1.328,2.571L0.506,0.038L2.697,-1.541L0,-1.541Z"
              style={{
                fill: "rgb(255,242,0)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,322.334,79.8609)">
            <Path
              d="M0,-1.534L-2.697,-1.534L-3.513,-4.112L-4.348,-1.534L-7.035,-1.534L-4.848,0.039L-5.678,2.578L-3.513,1.005L-1.362,2.578L-2.183,0.039L0,-1.534Z"
              style={{
                fill: "rgb(255,242,0)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,313.699,64.6172)">
            <Path
              d="M0,6.687L2.158,5.123L4.315,6.687L3.493,4.148L5.682,2.583L2.987,2.583L2.158,0L1.337,2.59L-1.355,2.583L0.829,4.148L0,6.687Z"
              style={{
                fill: "rgb(255,242,0)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,307.716,63.176)">
            <Path
              d="M0,-6.695L-0.814,-4.111L-3.518,-4.117L-1.328,-2.545L-2.144,0L0.014,-1.58L2.158,0L1.342,-2.545L3.525,-4.117L0.829,-4.117L0,-6.695Z"
              style={{
                fill: "rgb(255,242,0)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,85.4457,78.4077)">
            <Path
              d="M0,11.227L0,0L6.853,0L6.853,1.345L1.572,1.345L1.572,4.682L6.27,4.682L6.27,5.994L1.572,5.994L1.572,9.882L7.258,9.882L7.258,11.227L0,11.227Z"
              style={{
                fill: "rgb(35,31,32)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,100.917,81.4533)">
            <Path
              d="M0,7.76C-0.346,7.847 -0.802,7.938 -1.369,8.035C-1.936,8.132 -2.592,8.181 -3.337,8.181C-3.985,8.181 -4.531,8.087 -4.974,7.898C-5.417,7.709 -5.773,7.442 -6.043,7.096C-6.313,6.75 -6.507,6.342 -6.626,5.873C-6.745,5.403 -6.804,4.882 -6.804,4.31L-6.804,-0.421L-5.297,-0.421L-5.297,3.986C-5.297,5.012 -5.136,5.746 -4.811,6.189C-4.487,6.632 -3.942,6.853 -3.175,6.853C-3.013,6.853 -2.846,6.848 -2.673,6.837C-2.501,6.826 -2.338,6.812 -2.187,6.796C-2.036,6.78 -1.898,6.764 -1.774,6.748C-1.65,6.732 -1.561,6.713 -1.507,6.691L-1.507,-0.421L0,-0.421L0,7.76Z"
              style={{
                fill: "rgb(35,31,32)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,106.538,89.6346)">
            <Path
              d="M0,-8.602C0.13,-8.602 0.278,-8.594 0.446,-8.578C0.613,-8.561 0.778,-8.54 0.94,-8.513C1.102,-8.486 1.25,-8.459 1.385,-8.432C1.52,-8.405 1.62,-8.381 1.685,-8.359L1.426,-7.047C1.307,-7.09 1.11,-7.141 0.834,-7.201C0.559,-7.26 0.205,-7.29 -0.227,-7.29C-0.508,-7.29 -0.786,-7.26 -1.061,-7.201C-1.336,-7.141 -1.518,-7.101 -1.604,-7.079L-1.604,0L-3.11,0L-3.11,-8.067C-2.754,-8.197 -2.311,-8.319 -1.782,-8.432C-1.253,-8.545 -0.659,-8.602 0,-8.602"
              style={{
                fill: "rgb(35,31,32)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,115.464,85.2274)">
            <Path
              d="M0,0.001C0,-0.949 -0.213,-1.703 -0.64,-2.259C-1.066,-2.815 -1.647,-3.093 -2.381,-3.093C-3.116,-3.093 -3.696,-2.815 -4.123,-2.259C-4.549,-1.703 -4.763,-0.949 -4.763,0.001C-4.763,0.951 -4.549,1.705 -4.123,2.261C-3.696,2.817 -3.116,3.095 -2.381,3.095C-1.647,3.095 -1.066,2.817 -0.64,2.261C-0.213,1.705 0,0.951 0,0.001M1.572,0.001C1.572,0.67 1.475,1.275 1.28,1.815C1.085,2.356 0.813,2.82 0.462,3.209C0.111,3.597 -0.308,3.897 -0.794,4.108C-1.28,4.318 -1.809,4.423 -2.381,4.423C-2.954,4.423 -3.483,4.318 -3.969,4.108C-4.455,3.897 -4.874,3.597 -5.224,3.209C-5.575,2.82 -5.848,2.356 -6.042,1.815C-6.237,1.275 -6.334,0.67 -6.334,0.001C-6.334,-0.658 -6.237,-1.26 -6.042,-1.806C-5.848,-2.351 -5.575,-2.818 -5.224,-3.206C-4.874,-3.596 -4.455,-3.895 -3.969,-4.106C-3.483,-4.316 -2.954,-4.422 -2.381,-4.422C-1.809,-4.422 -1.28,-4.316 -0.794,-4.106C-0.308,-3.895 0.111,-3.596 0.462,-3.206C0.813,-2.818 1.085,-2.351 1.28,-1.806C1.475,-1.26 1.572,-0.658 1.572,0.001"
              style={{
                fill: "rgb(35,31,32)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,124.925,88.2259)">
            <Path
              d="M0,-2.787C0,-3.77 -0.243,-4.526 -0.729,-5.055C-1.215,-5.584 -1.863,-5.849 -2.673,-5.849C-3.126,-5.849 -3.48,-5.832 -3.734,-5.8C-3.988,-5.768 -4.19,-5.73 -4.341,-5.687L-4.341,-0.374C-4.158,-0.222 -3.893,-0.076 -3.548,0.064C-3.202,0.205 -2.824,0.275 -2.413,0.275C-1.982,0.275 -1.612,0.196 -1.304,0.04C-0.996,-0.117 -0.745,-0.333 -0.551,-0.608C-0.356,-0.884 -0.216,-1.207 -0.129,-1.58C-0.043,-1.953 0,-2.355 0,-2.787M1.572,-2.787C1.572,-2.15 1.488,-1.561 1.32,-1.021C1.153,-0.481 0.907,-0.017 0.584,0.372C0.259,0.761 -0.138,1.063 -0.607,1.279C-1.077,1.495 -1.615,1.603 -2.219,1.603C-2.705,1.603 -3.135,1.538 -3.507,1.409C-3.88,1.279 -4.158,1.155 -4.341,1.036L-4.341,4.406L-5.848,4.406L-5.848,-6.756C-5.492,-6.842 -5.046,-6.937 -4.512,-7.04C-3.977,-7.142 -3.359,-7.193 -2.657,-7.193C-2.009,-7.193 -1.425,-7.091 -0.907,-6.886C-0.389,-6.681 0.054,-6.389 0.421,-6.011C0.789,-5.633 1.072,-5.171 1.272,-4.626C1.472,-4.081 1.572,-3.467 1.572,-2.787"
              style={{
                fill: "rgb(35,31,32)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,133.851,86.0214)">
            <Path
              d="M0,-1.603C0.011,-2.284 -0.159,-2.842 -0.51,-3.28C-0.861,-3.717 -1.344,-3.936 -1.96,-3.936C-2.306,-3.936 -2.611,-3.868 -2.875,-3.733C-3.14,-3.598 -3.364,-3.423 -3.547,-3.207C-3.731,-2.991 -3.874,-2.743 -3.977,-2.462C-4.08,-2.181 -4.147,-1.895 -4.179,-1.603L0,-1.603ZM-5.783,-0.777C-5.783,-1.522 -5.675,-2.173 -5.459,-2.729C-5.243,-3.285 -4.957,-3.747 -4.601,-4.114C-4.244,-4.481 -3.834,-4.756 -3.369,-4.94C-2.905,-5.124 -2.43,-5.216 -1.944,-5.216C-0.81,-5.216 0.06,-4.862 0.665,-4.155C1.269,-3.447 1.572,-2.37 1.572,-0.923L1.572,-0.671C1.572,-0.569 1.566,-0.475 1.556,-0.388L-4.212,-0.388C-4.147,0.487 -3.893,1.151 -3.45,1.605C-3.008,2.058 -2.316,2.285 -1.377,2.285C-0.848,2.285 -0.402,2.239 -0.04,2.147C0.321,2.056 0.594,1.966 0.778,1.88L0.988,3.144C0.805,3.241 0.483,3.343 0.024,3.451C-0.435,3.56 -0.956,3.613 -1.539,3.613C-2.273,3.613 -2.908,3.502 -3.442,3.281C-3.977,3.06 -4.417,2.755 -4.763,2.366C-5.108,1.977 -5.365,1.516 -5.532,0.981C-5.7,0.446 -5.783,-0.14 -5.783,-0.777"
              style={{
                fill: "rgb(35,31,32)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,138.257,90.6869)">
            <Path
              d="M0,-10.999C-0.27,-10.999 -0.5,-11.088 -0.689,-11.266C-0.878,-11.444 -0.972,-11.685 -0.972,-11.987C-0.972,-12.289 -0.878,-12.53 -0.689,-12.708C-0.5,-12.886 -0.27,-12.976 0,-12.976C0.27,-12.976 0.499,-12.886 0.688,-12.708C0.877,-12.53 0.972,-12.289 0.972,-11.987C0.972,-11.685 0.877,-11.444 0.688,-11.266C0.499,-11.088 0.27,-10.999 0,-10.999M-2.122,1.977C-2.252,1.977 -2.419,1.961 -2.624,1.929C-2.829,1.896 -3.002,1.853 -3.143,1.799L-2.948,0.568C-2.84,0.6 -2.716,0.627 -2.576,0.649C-2.435,0.67 -2.306,0.681 -2.187,0.681C-1.669,0.681 -1.299,0.522 -1.077,0.203C-0.856,-0.115 -0.745,-0.582 -0.745,-1.198L-0.745,-9.476L0.762,-9.476L0.762,-1.214C0.762,-0.134 0.516,0.668 0.024,1.191C-0.467,1.715 -1.183,1.977 -2.122,1.977"
              style={{
                fill: "rgb(35,31,32)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,143.49,82.0859)">
            <Path
              d="M0,6.269C0.615,6.269 1.072,6.188 1.369,6.026C1.665,5.864 1.814,5.605 1.814,5.248C1.814,4.881 1.668,4.59 1.377,4.373C1.085,4.158 0.604,3.914 -0.065,3.644C-0.389,3.515 -0.7,3.383 -0.997,3.248C-1.294,3.113 -1.55,2.953 -1.766,2.77C-1.982,2.586 -2.155,2.365 -2.284,2.105C-2.414,1.846 -2.479,1.528 -2.479,1.15C-2.479,0.405 -2.203,-0.187 -1.652,-0.624C-1.102,-1.062 -0.351,-1.28 0.599,-1.28C0.837,-1.28 1.074,-1.267 1.312,-1.24C1.549,-1.213 1.771,-1.18 1.976,-1.143C2.181,-1.105 2.362,-1.064 2.519,-1.021C2.675,-0.978 2.797,-0.94 2.883,-0.908L2.608,0.389C2.446,0.302 2.192,0.213 1.847,0.121C1.501,0.029 1.085,-0.017 0.599,-0.017C0.178,-0.017 -0.189,0.067 -0.503,0.234C-0.816,0.402 -0.972,0.664 -0.972,1.02C-0.972,1.204 -0.937,1.366 -0.867,1.506C-0.797,1.646 -0.689,1.773 -0.543,1.887C-0.397,2 -0.216,2.105 0,2.203C0.216,2.3 0.475,2.403 0.777,2.511C1.177,2.662 1.533,2.81 1.847,2.956C2.16,3.102 2.427,3.272 2.648,3.466C2.87,3.661 3.04,3.895 3.159,4.171C3.277,4.446 3.337,4.784 3.337,5.183C3.337,5.961 3.048,6.55 2.47,6.949C1.893,7.349 1.069,7.549 0,7.549C-0.745,7.549 -1.329,7.487 -1.749,7.362C-2.171,7.238 -2.457,7.144 -2.608,7.079L-2.333,5.783C-2.16,5.848 -1.885,5.945 -1.507,6.075C-1.129,6.204 -0.626,6.269 0,6.269"
              style={{
                fill: "rgb(35,31,32)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,151.719,81.6964)">
            <Path
              d="M0,3.305C0.324,3.553 0.667,3.862 1.028,4.228C1.39,4.596 1.747,4.992 2.098,5.419C2.449,5.846 2.781,6.278 3.094,6.715C3.407,7.153 3.672,7.56 3.888,7.938L2.122,7.938C1.895,7.56 1.633,7.174 1.336,6.78C1.039,6.386 0.729,6.008 0.405,5.646C0.081,5.284 -0.249,4.952 -0.583,4.65C-0.918,4.347 -1.237,4.093 -1.539,3.888L-1.539,7.938L-3.046,7.938L-3.046,-4.374L-1.539,-4.633L-1.539,3.046C-1.28,2.787 -0.994,2.501 -0.681,2.187C-0.368,1.874 -0.06,1.555 0.243,1.231C0.545,0.907 0.832,0.597 1.102,0.3C1.371,0.003 1.604,-0.259 1.798,-0.486L3.564,-0.486C3.315,-0.216 3.045,0.081 2.754,0.405C2.462,0.729 2.16,1.056 1.847,1.385C1.533,1.715 1.217,2.044 0.899,2.373C0.58,2.703 0.281,3.013 0,3.305"
              style={{
                fill: "rgb(35,31,32)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,0,8.613)">
            <Path
              d="M158.556,81.022L157.049,81.022L157.049,72.598L158.556,72.598L158.556,81.022ZM157.794,71.075C157.524,71.075 157.294,70.985 157.105,70.808C156.916,70.63 156.822,70.389 156.822,70.087C156.822,69.784 156.916,69.544 157.105,69.366C157.294,69.188 157.524,69.098 157.794,69.098C158.064,69.098 158.293,69.188 158.482,69.366C158.671,69.544 158.766,69.784 158.766,70.087C158.766,70.389 158.671,70.63 158.482,70.808C158.293,70.985 158.064,71.075 157.794,71.075"
              style={{
                fill: "rgb(35,31,32)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,164.824,78.4077)">
            <Path
              d="M0,11.227L0,0L6.772,0L6.772,1.345L1.572,1.345L1.572,4.731L6.189,4.731L6.189,6.059L1.572,6.059L1.572,11.227L0,11.227Z"
              style={{
                fill: "rgb(35,31,32)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,179.647,81.4533)">
            <Path
              d="M0,7.76C-0.346,7.847 -0.802,7.938 -1.369,8.035C-1.936,8.132 -2.592,8.181 -3.337,8.181C-3.985,8.181 -4.531,8.087 -4.974,7.898C-5.417,7.709 -5.773,7.442 -6.043,7.096C-6.313,6.75 -6.507,6.342 -6.626,5.873C-6.745,5.403 -6.804,4.882 -6.804,4.31L-6.804,-0.421L-5.297,-0.421L-5.297,3.986C-5.297,5.012 -5.136,5.746 -4.811,6.189C-4.487,6.632 -3.942,6.853 -3.175,6.853C-3.013,6.853 -2.846,6.848 -2.673,6.837C-2.501,6.826 -2.338,6.812 -2.187,6.796C-2.036,6.78 -1.898,6.764 -1.774,6.748C-1.65,6.732 -1.561,6.713 -1.507,6.691L-1.507,-0.421L0,-0.421L0,7.76Z"
              style={{
                fill: "rgb(35,31,32)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,182.158,89.2135)">
            <Path
              d="M0,-7.76C0.346,-7.846 0.805,-7.938 1.377,-8.035C1.949,-8.132 2.608,-8.181 3.354,-8.181C4.023,-8.181 4.579,-8.086 5.022,-7.898C5.465,-7.708 5.818,-7.444 6.083,-7.104C6.348,-6.763 6.534,-6.356 6.642,-5.881C6.75,-5.405 6.804,-4.882 6.804,-4.309L6.804,0.421L5.297,0.421L5.297,-3.985C5.297,-4.504 5.262,-4.946 5.192,-5.314C5.122,-5.681 5.006,-5.978 4.844,-6.205C4.682,-6.431 4.466,-6.596 4.196,-6.699C3.926,-6.801 3.591,-6.853 3.191,-6.853C3.029,-6.853 2.862,-6.847 2.689,-6.837C2.517,-6.825 2.352,-6.812 2.195,-6.796C2.039,-6.78 1.898,-6.761 1.774,-6.739C1.65,-6.717 1.561,-6.701 1.507,-6.691L1.507,0.421L0,0.421L0,-7.76Z"
              style={{
                fill: "rgb(35,31,32)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,196.819,83.7062)">
            <Path
              d="M0,-0.908C-0.184,-1.059 -0.448,-1.205 -0.794,-1.345C-1.14,-1.486 -1.518,-1.556 -1.928,-1.556C-2.36,-1.556 -2.73,-1.477 -3.038,-1.321C-3.345,-1.164 -3.597,-0.949 -3.791,-0.673C-3.985,-0.398 -4.126,-0.071 -4.212,0.307C-4.299,0.685 -4.342,1.09 -4.342,1.522C-4.342,2.505 -4.099,3.263 -3.613,3.798C-3.126,4.333 -2.479,4.6 -1.669,4.6C-1.258,4.6 -0.916,4.581 -0.64,4.543C-0.365,4.506 -0.151,4.465 0,4.422L0,-0.908ZM0,-6.578L1.506,-6.837L1.506,5.491C1.161,5.588 0.718,5.685 0.178,5.782C-0.362,5.88 -0.983,5.929 -1.685,5.929C-2.333,5.929 -2.916,5.826 -3.435,5.621C-3.953,5.416 -4.396,5.124 -4.763,4.746C-5.13,4.368 -5.414,3.906 -5.613,3.361C-5.814,2.816 -5.913,2.202 -5.913,1.522C-5.913,0.874 -5.83,0.28 -5.662,-0.26C-5.495,-0.8 -5.249,-1.264 -4.925,-1.653C-4.601,-2.042 -4.204,-2.344 -3.734,-2.56C-3.264,-2.776 -2.727,-2.885 -2.122,-2.885C-1.636,-2.885 -1.207,-2.82 -0.835,-2.69C-0.462,-2.56 -0.184,-2.436 0,-2.317L0,-6.578Z"
              style={{
                fill: "rgb(35,31,32)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,207.543,81.4533)">
            <Path
              d="M0,7.76C-0.346,7.847 -0.802,7.938 -1.369,8.035C-1.936,8.132 -2.592,8.181 -3.337,8.181C-3.985,8.181 -4.531,8.087 -4.974,7.898C-5.416,7.709 -5.773,7.442 -6.043,7.096C-6.313,6.75 -6.507,6.342 -6.626,5.873C-6.745,5.403 -6.804,4.882 -6.804,4.31L-6.804,-0.421L-5.297,-0.421L-5.297,3.986C-5.297,5.012 -5.136,5.746 -4.811,6.189C-4.487,6.632 -3.942,6.853 -3.175,6.853C-3.013,6.853 -2.846,6.848 -2.673,6.837C-2.501,6.826 -2.338,6.812 -2.187,6.796C-2.036,6.78 -1.898,6.764 -1.774,6.748C-1.65,6.732 -1.561,6.713 -1.507,6.691L-1.507,-0.421L0,-0.421L0,7.76Z"
              style={{
                fill: "rgb(35,31,32)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,212.014,82.0859)">
            <Path
              d="M0,6.269C0.615,6.269 1.072,6.188 1.369,6.026C1.665,5.864 1.814,5.605 1.814,5.248C1.814,4.881 1.668,4.59 1.377,4.373C1.085,4.158 0.605,3.914 -0.065,3.644C-0.389,3.515 -0.7,3.383 -0.997,3.248C-1.294,3.113 -1.55,2.953 -1.766,2.77C-1.982,2.586 -2.155,2.365 -2.284,2.105C-2.414,1.846 -2.479,1.528 -2.479,1.15C-2.479,0.405 -2.203,-0.187 -1.652,-0.624C-1.102,-1.062 -0.351,-1.28 0.599,-1.28C0.837,-1.28 1.074,-1.267 1.312,-1.24C1.549,-1.213 1.771,-1.18 1.976,-1.143C2.181,-1.105 2.362,-1.064 2.519,-1.021C2.675,-0.978 2.797,-0.94 2.883,-0.908L2.608,0.389C2.446,0.302 2.192,0.213 1.847,0.121C1.501,0.029 1.085,-0.017 0.599,-0.017C0.178,-0.017 -0.189,0.067 -0.503,0.234C-0.816,0.402 -0.972,0.664 -0.972,1.02C-0.972,1.204 -0.937,1.366 -0.867,1.506C-0.797,1.646 -0.688,1.773 -0.543,1.887C-0.397,2 -0.216,2.105 0,2.203C0.216,2.3 0.475,2.403 0.777,2.511C1.177,2.662 1.533,2.81 1.847,2.956C2.16,3.102 2.427,3.272 2.648,3.466C2.87,3.661 3.04,3.895 3.159,4.171C3.277,4.446 3.337,4.784 3.337,5.183C3.337,5.961 3.048,6.55 2.47,6.949C1.893,7.349 1.069,7.549 0,7.549C-0.745,7.549 -1.329,7.487 -1.749,7.362C-2.171,7.238 -2.457,7.144 -2.608,7.079L-2.333,5.783C-2.16,5.848 -1.885,5.945 -1.507,6.075C-1.129,6.204 -0.626,6.269 0,6.269"
              style={{
                fill: "rgb(35,31,32)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,222.787,88.5006)">
            <Path
              d="M0,-6.156C-0.227,-5.907 -0.526,-5.556 -0.899,-5.103C-1.271,-4.649 -1.671,-4.147 -2.098,-3.596C-2.524,-3.046 -2.951,-2.468 -3.377,-1.863C-3.804,-1.258 -4.179,-0.68 -4.503,-0.13L0.13,-0.13L0.13,1.134L-6.302,1.134L-6.302,0.13C-6.042,-0.345 -5.724,-0.869 -5.346,-1.442C-4.968,-2.014 -4.571,-2.584 -4.155,-3.151C-3.739,-3.718 -3.334,-4.253 -2.94,-4.755C-2.546,-5.257 -2.203,-5.68 -1.911,-6.026L-6.075,-6.026L-6.075,-7.29L0,-7.29L0,-6.156Z"
              style={{
                fill: "rgb(35,31,32)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,61.7123,108.108)">
            <Path
              d="M0,-8.586C-0.669,-8.586 -1.183,-8.57 -1.538,-8.538L-1.538,-4.309L-0.421,-4.309C0.119,-4.309 0.605,-4.336 1.037,-4.39C1.469,-4.444 1.834,-4.547 2.13,-4.698C2.427,-4.849 2.657,-5.063 2.819,-5.338C2.981,-5.613 3.062,-5.978 3.062,-6.432C3.062,-6.853 2.981,-7.204 2.819,-7.484C2.657,-7.765 2.439,-7.987 2.163,-8.149C1.888,-8.311 1.564,-8.424 1.191,-8.489C0.818,-8.554 0.421,-8.586 0,-8.586M2.381,-3.321C2.554,-3.105 2.773,-2.821 3.038,-2.471C3.302,-2.12 3.575,-1.731 3.856,-1.304C4.137,-0.878 4.415,-0.432 4.69,0.032C4.965,0.497 5.2,0.945 5.395,1.377L3.678,1.377C3.462,0.967 3.227,0.551 2.973,0.129C2.719,-0.292 2.462,-0.694 2.204,-1.077C1.944,-1.46 1.687,-1.82 1.434,-2.155C1.18,-2.489 0.95,-2.781 0.746,-3.029C0.605,-3.019 0.462,-3.013 0.316,-3.013L-1.538,-3.013L-1.538,1.377L-3.11,1.377L-3.11,-9.688C-2.667,-9.796 -2.173,-9.868 -1.628,-9.906C-1.082,-9.944 -0.583,-9.963 -0.129,-9.963C1.447,-9.963 2.649,-9.666 3.475,-9.072C4.301,-8.478 4.714,-7.592 4.714,-6.415C4.714,-5.67 4.517,-5.033 4.123,-4.503C3.729,-3.974 3.149,-3.58 2.381,-3.321"
              style={{
                fill: "rgb(35,31,32)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,74.478,105.077)">
            <Path
              d="M0,0.001C0,-0.949 -0.213,-1.703 -0.64,-2.259C-1.066,-2.815 -1.647,-3.093 -2.381,-3.093C-3.116,-3.093 -3.696,-2.815 -4.123,-2.259C-4.549,-1.703 -4.763,-0.949 -4.763,0.001C-4.763,0.951 -4.549,1.705 -4.123,2.261C-3.696,2.817 -3.116,3.095 -2.381,3.095C-1.647,3.095 -1.066,2.817 -0.64,2.261C-0.213,1.705 0,0.951 0,0.001M1.572,0.001C1.572,0.67 1.475,1.275 1.28,1.815C1.085,2.355 0.813,2.82 0.462,3.208C0.111,3.597 -0.308,3.897 -0.793,4.107C-1.28,4.318 -1.809,4.423 -2.381,4.423C-2.954,4.423 -3.483,4.318 -3.969,4.107C-4.455,3.897 -4.874,3.597 -5.224,3.208C-5.575,2.82 -5.848,2.355 -6.042,1.815C-6.237,1.275 -6.334,0.67 -6.334,0.001C-6.334,-0.658 -6.237,-1.26 -6.042,-1.806C-5.848,-2.351 -5.575,-2.818 -5.224,-3.207C-4.874,-3.596 -4.455,-3.895 -3.969,-4.106C-3.483,-4.316 -2.954,-4.422 -2.381,-4.422C-1.809,-4.422 -1.28,-4.316 -0.793,-4.106C-0.308,-3.895 0.111,-3.596 0.462,-3.207C0.813,-2.818 1.085,-2.351 1.28,-1.806C1.475,-1.26 1.572,-0.658 1.572,0.001"
              style={{
                fill: "rgb(35,31,32)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,83.3554,108.351)">
            <Path
              d="M0,-6.156C-0.227,-5.907 -0.526,-5.556 -0.899,-5.103C-1.271,-4.649 -1.671,-4.147 -2.098,-3.596C-2.524,-3.046 -2.951,-2.468 -3.377,-1.863C-3.804,-1.258 -4.179,-0.68 -4.503,-0.13L0.13,-0.13L0.13,1.134L-6.302,1.134L-6.302,0.13C-6.043,-0.345 -5.724,-0.869 -5.346,-1.442C-4.968,-2.014 -4.571,-2.584 -4.155,-3.151C-3.739,-3.718 -3.335,-4.253 -2.94,-4.755C-2.546,-5.257 -2.203,-5.68 -1.911,-6.026L-6.075,-6.026L-6.075,-7.29L0,-7.29L0,-6.156Z"
              style={{
                fill: "rgb(35,31,32)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,92.2011,101.061)">
            <Path
              d="M0,8.424C-0.303,7.646 -0.624,6.758 -0.964,5.759C-1.304,4.76 -1.615,3.721 -1.896,2.641C-2.177,3.721 -2.484,4.76 -2.819,5.759C-3.154,6.758 -3.473,7.646 -3.775,8.424L-5.087,8.424C-5.584,7.312 -6.07,6.043 -6.545,4.617C-7.021,3.192 -7.468,1.653 -7.89,0L-6.27,0C-6.162,0.508 -6.032,1.059 -5.881,1.653C-5.73,2.246 -5.57,2.841 -5.403,3.434C-5.235,4.028 -5.06,4.601 -4.877,5.151C-4.693,5.702 -4.52,6.183 -4.358,6.593C-4.174,6.086 -3.994,5.548 -3.815,4.982C-3.637,4.415 -3.467,3.842 -3.305,3.264C-3.143,2.687 -2.989,2.12 -2.843,1.563C-2.697,1.007 -2.571,0.486 -2.462,0L-1.215,0C-1.118,0.486 -0.999,1.007 -0.859,1.563C-0.719,2.12 -0.567,2.687 -0.405,3.264C-0.243,3.842 -0.073,4.415 0.105,4.982C0.283,5.548 0.464,6.086 0.648,6.593C0.81,6.183 0.98,5.702 1.158,5.151C1.336,4.601 1.512,4.028 1.685,3.434C1.857,2.841 2.019,2.246 2.17,1.653C2.322,1.059 2.451,0.508 2.56,0L4.115,0C3.693,1.653 3.245,3.192 2.77,4.617C2.295,6.043 1.809,7.312 1.312,8.424L0,8.424Z"
              style={{
                fill: "rgb(35,31,32)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,103.492,105.077)">
            <Path
              d="M0,0.001C0,-0.949 -0.213,-1.703 -0.64,-2.259C-1.066,-2.815 -1.647,-3.093 -2.381,-3.093C-3.116,-3.093 -3.696,-2.815 -4.123,-2.259C-4.549,-1.703 -4.763,-0.949 -4.763,0.001C-4.763,0.951 -4.549,1.705 -4.123,2.261C-3.696,2.817 -3.116,3.095 -2.381,3.095C-1.647,3.095 -1.066,2.817 -0.64,2.261C-0.213,1.705 0,0.951 0,0.001M1.572,0.001C1.572,0.67 1.475,1.275 1.28,1.815C1.085,2.355 0.813,2.82 0.462,3.208C0.111,3.597 -0.308,3.897 -0.793,4.107C-1.28,4.318 -1.809,4.423 -2.381,4.423C-2.954,4.423 -3.483,4.318 -3.969,4.107C-4.455,3.897 -4.874,3.597 -5.224,3.208C-5.575,2.82 -5.848,2.355 -6.043,1.815C-6.237,1.275 -6.334,0.67 -6.334,0.001C-6.334,-0.658 -6.237,-1.26 -6.043,-1.806C-5.848,-2.351 -5.575,-2.818 -5.224,-3.207C-4.874,-3.596 -4.455,-3.895 -3.969,-4.106C-3.483,-4.316 -2.954,-4.422 -2.381,-4.422C-1.809,-4.422 -1.28,-4.316 -0.793,-4.106C-0.308,-3.895 0.111,-3.596 0.462,-3.207C0.813,-2.818 1.085,-2.351 1.28,-1.806C1.475,-1.26 1.572,-0.658 1.572,0.001"
              style={{
                fill: "rgb(35,31,32)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,107.85,110.537)">
            <Path
              d="M0,-10.999C-0.27,-10.999 -0.499,-11.088 -0.689,-11.266C-0.877,-11.444 -0.972,-11.685 -0.972,-11.987C-0.972,-12.289 -0.877,-12.53 -0.689,-12.708C-0.499,-12.886 -0.27,-12.976 0,-12.976C0.27,-12.976 0.499,-12.886 0.688,-12.708C0.878,-12.53 0.972,-12.289 0.972,-11.987C0.972,-11.685 0.878,-11.444 0.688,-11.266C0.499,-11.088 0.27,-10.999 0,-10.999M-2.122,1.977C-2.251,1.977 -2.419,1.961 -2.624,1.929C-2.829,1.896 -3.002,1.853 -3.143,1.799L-2.948,0.568C-2.84,0.6 -2.716,0.627 -2.576,0.649C-2.435,0.67 -2.306,0.681 -2.187,0.681C-1.668,0.681 -1.299,0.522 -1.077,0.203C-0.856,-0.115 -0.745,-0.582 -0.745,-1.198L-0.745,-9.476L0.762,-9.476L0.762,-1.214C0.762,-0.134 0.516,0.668 0.024,1.191C-0.467,1.715 -1.183,1.977 -2.122,1.977"
              style={{
                fill: "rgb(35,31,32)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,117.829,101.303)">
            <Path
              d="M0,7.76C-0.346,7.847 -0.802,7.938 -1.369,8.035C-1.936,8.132 -2.592,8.181 -3.337,8.181C-3.985,8.181 -4.531,8.087 -4.974,7.898C-5.417,7.709 -5.773,7.442 -6.043,7.096C-6.313,6.75 -6.507,6.343 -6.626,5.873C-6.745,5.403 -6.804,4.882 -6.804,4.31L-6.804,-0.421L-5.297,-0.421L-5.297,3.986C-5.297,5.012 -5.136,5.746 -4.812,6.189C-4.487,6.632 -3.942,6.853 -3.175,6.853C-3.013,6.853 -2.846,6.848 -2.673,6.837C-2.501,6.826 -2.338,6.812 -2.187,6.796C-2.036,6.78 -1.898,6.764 -1.774,6.748C-1.65,6.732 -1.561,6.713 -1.507,6.691L-1.507,-0.421L0,-0.421L0,7.76Z"
              style={{
                fill: "rgb(35,31,32)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,127.208,108.108)">
            <Path
              d="M0,-8.586C-0.669,-8.586 -1.183,-8.57 -1.539,-8.538L-1.539,-4.309L-0.421,-4.309C0.119,-4.309 0.605,-4.336 1.037,-4.39C1.469,-4.444 1.833,-4.547 2.13,-4.698C2.427,-4.849 2.657,-5.063 2.819,-5.338C2.981,-5.613 3.062,-5.978 3.062,-6.432C3.062,-6.853 2.981,-7.204 2.819,-7.484C2.657,-7.765 2.438,-7.987 2.163,-8.149C1.888,-8.311 1.563,-8.424 1.191,-8.489C0.818,-8.554 0.421,-8.586 0,-8.586M2.381,-3.321C2.554,-3.105 2.773,-2.821 3.038,-2.471C3.302,-2.12 3.575,-1.731 3.856,-1.304C4.137,-0.878 4.415,-0.432 4.69,0.032C4.965,0.497 5.2,0.945 5.395,1.377L3.678,1.377C3.461,0.967 3.227,0.551 2.973,0.129C2.719,-0.292 2.462,-0.694 2.204,-1.077C1.944,-1.46 1.687,-1.82 1.434,-2.155C1.18,-2.489 0.95,-2.781 0.746,-3.029C0.605,-3.019 0.462,-3.013 0.316,-3.013L-1.539,-3.013L-1.539,1.377L-3.11,1.377L-3.11,-9.688C-2.667,-9.796 -2.173,-9.868 -1.628,-9.906C-1.083,-9.944 -0.583,-9.963 -0.129,-9.963C1.447,-9.963 2.649,-9.666 3.475,-9.072C4.301,-8.478 4.714,-7.592 4.714,-6.415C4.714,-5.67 4.517,-5.033 4.123,-4.503C3.729,-3.974 3.148,-3.58 2.381,-3.321"
              style={{
                fill: "rgb(35,31,32)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,139.423,105.871)">
            <Path
              d="M0,-1.603C0.011,-2.284 -0.159,-2.842 -0.51,-3.28C-0.861,-3.717 -1.344,-3.936 -1.96,-3.936C-2.306,-3.936 -2.611,-3.868 -2.876,-3.733C-3.14,-3.598 -3.364,-3.423 -3.547,-3.207C-3.732,-2.991 -3.875,-2.743 -3.977,-2.462C-4.08,-2.181 -4.147,-1.895 -4.179,-1.603L0,-1.603ZM-5.783,-0.777C-5.783,-1.522 -5.675,-2.173 -5.459,-2.729C-5.243,-3.285 -4.957,-3.747 -4.601,-4.114C-4.244,-4.481 -3.834,-4.756 -3.369,-4.94C-2.905,-5.124 -2.43,-5.216 -1.944,-5.216C-0.81,-5.216 0.059,-4.862 0.664,-4.155C1.269,-3.447 1.572,-2.37 1.572,-0.923L1.572,-0.671C1.572,-0.569 1.566,-0.475 1.556,-0.388L-4.212,-0.388C-4.147,0.487 -3.894,1.151 -3.45,1.605C-3.008,2.058 -2.316,2.285 -1.377,2.285C-0.848,2.285 -0.402,2.239 -0.04,2.147C0.321,2.056 0.594,1.966 0.778,1.88L0.988,3.144C0.805,3.241 0.483,3.343 0.024,3.451C-0.435,3.56 -0.956,3.613 -1.539,3.613C-2.273,3.613 -2.908,3.502 -3.442,3.281C-3.977,3.06 -4.417,2.755 -4.763,2.366C-5.108,1.977 -5.365,1.516 -5.532,0.981C-5.7,0.446 -5.783,-0.14 -5.783,-0.777"
              style={{
                fill: "rgb(35,31,32)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,148.349,111.024)">
            <Path
              d="M0,-8.635C-0.151,-8.678 -0.354,-8.718 -0.607,-8.757C-0.861,-8.794 -1.204,-8.813 -1.636,-8.813C-2.446,-8.813 -3.07,-8.548 -3.507,-8.019C-3.945,-7.49 -4.163,-6.788 -4.163,-5.913C-4.163,-5.427 -4.102,-5.011 -3.977,-4.666C-3.853,-4.32 -3.686,-4.034 -3.475,-3.807C-3.264,-3.58 -3.021,-3.413 -2.746,-3.305C-2.471,-3.197 -2.187,-3.143 -1.895,-3.143C-1.496,-3.143 -1.129,-3.2 -0.794,-3.313C-0.459,-3.427 -0.194,-3.559 0,-3.71L0,-8.635ZM0.016,-2.382C-0.113,-2.295 -0.365,-2.184 -0.737,-2.05C-1.11,-1.914 -1.544,-1.847 -2.041,-1.847C-2.549,-1.847 -3.027,-1.928 -3.475,-2.09C-3.923,-2.252 -4.315,-2.503 -4.649,-2.843C-4.984,-3.184 -5.249,-3.607 -5.443,-4.115C-5.638,-4.623 -5.735,-5.227 -5.735,-5.929C-5.735,-6.545 -5.643,-7.109 -5.459,-7.622C-5.276,-8.135 -5.009,-8.578 -4.658,-8.951C-4.307,-9.323 -3.877,-9.615 -3.37,-9.826C-2.862,-10.036 -2.29,-10.141 -1.652,-10.141C-0.951,-10.141 -0.338,-10.09 0.186,-9.988C0.71,-9.885 1.15,-9.79 1.506,-9.704L1.506,-2.187C1.506,-0.891 1.172,0.048 0.502,0.632C-0.167,1.215 -1.183,1.506 -2.543,1.506C-3.073,1.506 -3.572,1.463 -4.042,1.377C-4.512,1.29 -4.919,1.188 -5.265,1.069L-4.99,-0.243C-4.687,-0.125 -4.317,-0.019 -3.88,0.073C-3.442,0.164 -2.986,0.21 -2.511,0.21C-1.615,0.21 -0.97,0.032 -0.575,-0.324C-0.181,-0.681 0.016,-1.248 0.016,-2.025L0.016,-2.382Z"
              style={{
                fill: "rgb(35,31,32)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,0,48.313)">
            <Path
              d="M153.874,61.172L152.367,61.172L152.367,52.748L153.874,52.748L153.874,61.172ZM153.112,51.225C152.842,51.225 152.612,51.136 152.423,50.958C152.234,50.78 152.14,50.539 152.14,50.237C152.14,49.934 152.234,49.694 152.423,49.516C152.612,49.338 152.842,49.248 153.112,49.248C153.382,49.248 153.611,49.338 153.8,49.516C153.989,49.694 154.084,49.934 154.084,50.237C154.084,50.539 153.989,50.78 153.8,50.958C153.611,51.136 153.382,51.225 153.112,51.225"
              style={{
                fill: "rgb(35,31,32)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,162.248,105.077)">
            <Path
              d="M0,0.001C0,-0.949 -0.213,-1.703 -0.64,-2.259C-1.066,-2.815 -1.647,-3.093 -2.381,-3.093C-3.116,-3.093 -3.696,-2.815 -4.123,-2.259C-4.549,-1.703 -4.763,-0.949 -4.763,0.001C-4.763,0.951 -4.549,1.705 -4.123,2.261C-3.696,2.817 -3.116,3.095 -2.381,3.095C-1.647,3.095 -1.066,2.817 -0.64,2.261C-0.213,1.705 0,0.951 0,0.001M1.572,0.001C1.572,0.67 1.475,1.275 1.28,1.815C1.085,2.355 0.813,2.82 0.462,3.208C0.111,3.597 -0.308,3.897 -0.794,4.107C-1.28,4.318 -1.809,4.423 -2.381,4.423C-2.954,4.423 -3.483,4.318 -3.969,4.107C-4.455,3.897 -4.874,3.597 -5.224,3.208C-5.575,2.82 -5.848,2.355 -6.043,1.815C-6.237,1.275 -6.334,0.67 -6.334,0.001C-6.334,-0.658 -6.237,-1.26 -6.043,-1.806C-5.848,-2.351 -5.575,-2.818 -5.224,-3.207C-4.874,-3.596 -4.455,-3.895 -3.969,-4.106C-3.483,-4.316 -2.954,-4.422 -2.381,-4.422C-1.809,-4.422 -1.28,-4.316 -0.794,-4.106C-0.308,-3.895 0.111,-3.596 0.462,-3.207C0.813,-2.818 1.085,-2.351 1.28,-1.806C1.475,-1.26 1.572,-0.658 1.572,0.001"
              style={{
                fill: "rgb(35,31,32)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,165.861,109.064)">
            <Path
              d="M0,-7.76C0.346,-7.846 0.805,-7.938 1.377,-8.035C1.949,-8.133 2.608,-8.181 3.354,-8.181C4.023,-8.181 4.579,-8.086 5.022,-7.898C5.465,-7.708 5.818,-7.444 6.083,-7.104C6.348,-6.763 6.534,-6.356 6.642,-5.881C6.75,-5.406 6.804,-4.882 6.804,-4.309L6.804,0.421L5.297,0.421L5.297,-3.985C5.297,-4.504 5.262,-4.947 5.192,-5.314C5.122,-5.681 5.006,-5.978 4.844,-6.205C4.682,-6.431 4.466,-6.596 4.196,-6.699C3.926,-6.802 3.591,-6.853 3.191,-6.853C3.029,-6.853 2.862,-6.847 2.689,-6.837C2.517,-6.825 2.352,-6.812 2.195,-6.796C2.039,-6.78 1.898,-6.761 1.774,-6.739C1.65,-6.718 1.561,-6.701 1.507,-6.691L1.507,0.421L0,0.421L0,-7.76Z"
              style={{
                fill: "rgb(35,31,32)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,177.93,101.952)">
            <Path
              d="M0,6.285C0.357,6.285 0.672,6.277 0.948,6.261C1.223,6.245 1.453,6.215 1.636,6.172L1.636,3.661C1.528,3.607 1.353,3.561 1.11,3.523C0.867,3.485 0.573,3.466 0.227,3.466C0,3.466 -0.24,3.483 -0.494,3.515C-0.747,3.547 -0.98,3.615 -1.19,3.718C-1.401,3.82 -1.577,3.96 -1.717,4.139C-1.857,4.317 -1.928,4.552 -1.928,4.844C-1.928,5.384 -1.755,5.759 -1.409,5.969C-1.063,6.18 -0.594,6.285 0,6.285M-0.129,-1.264C0.476,-1.264 0.986,-1.186 1.401,-1.029C1.817,-0.872 2.152,-0.651 2.406,-0.365C2.66,-0.079 2.84,0.262 2.949,0.656C3.057,1.05 3.111,1.485 3.111,1.96L3.111,7.225C2.981,7.246 2.8,7.276 2.568,7.314C2.336,7.352 2.074,7.387 1.782,7.419C1.491,7.452 1.175,7.481 0.835,7.508C0.494,7.535 0.157,7.549 -0.178,7.549C-0.653,7.549 -1.091,7.5 -1.49,7.403C-1.89,7.306 -2.235,7.152 -2.527,6.941C-2.818,6.731 -3.045,6.453 -3.207,6.107C-3.369,5.762 -3.45,5.346 -3.45,4.86C-3.45,4.395 -3.356,3.995 -3.167,3.661C-2.978,3.326 -2.721,3.056 -2.397,2.851C-2.073,2.646 -1.695,2.495 -1.263,2.397C-0.831,2.3 -0.378,2.251 0.098,2.251C0.249,2.251 0.405,2.26 0.567,2.276C0.729,2.292 0.883,2.314 1.029,2.341C1.175,2.368 1.302,2.392 1.41,2.413C1.518,2.435 1.593,2.452 1.636,2.462L1.636,2.041C1.636,1.792 1.609,1.547 1.556,1.304C1.502,1.061 1.404,0.845 1.264,0.656C1.124,0.467 0.932,0.315 0.689,0.202C0.446,0.089 0.13,0.032 -0.259,0.032C-0.756,0.032 -1.19,0.067 -1.563,0.137C-1.936,0.208 -2.214,0.281 -2.397,0.356L-2.576,-0.891C-2.381,-0.978 -2.057,-1.062 -1.604,-1.143C-1.15,-1.223 -0.659,-1.264 -0.129,-1.264"
              style={{
                fill: "rgb(35,31,32)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,185.981,96.7518)">
            <Path
              d="M0,12.733C-0.929,12.712 -1.587,12.512 -1.977,12.133C-2.365,11.755 -2.56,11.167 -2.56,10.368L-2.56,0.259L-1.053,0L-1.053,10.125C-1.053,10.373 -1.032,10.578 -0.988,10.74C-0.945,10.902 -0.875,11.032 -0.778,11.129C-0.681,11.226 -0.551,11.299 -0.389,11.348C-0.227,11.396 -0.027,11.437 0.21,11.469L0,12.733Z"
              style={{
                fill: "rgb(35,31,32)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,187.795,109.064)">
            <Path
              d="M0,-7.76C0.346,-7.846 0.805,-7.938 1.377,-8.035C1.949,-8.133 2.608,-8.181 3.354,-8.181C4.023,-8.181 4.579,-8.086 5.022,-7.898C5.465,-7.708 5.818,-7.444 6.083,-7.104C6.348,-6.763 6.534,-6.356 6.642,-5.881C6.75,-5.406 6.804,-4.882 6.804,-4.309L6.804,0.421L5.297,0.421L5.297,-3.985C5.297,-4.504 5.262,-4.947 5.192,-5.314C5.122,-5.681 5.006,-5.978 4.844,-6.205C4.682,-6.431 4.466,-6.596 4.196,-6.699C3.926,-6.802 3.591,-6.853 3.191,-6.853C3.029,-6.853 2.862,-6.847 2.689,-6.837C2.517,-6.825 2.352,-6.812 2.195,-6.796C2.039,-6.78 1.898,-6.761 1.774,-6.739C1.65,-6.718 1.561,-6.701 1.507,-6.691L1.507,0.421L0,0.421L0,-7.76Z"
              style={{
                fill: "rgb(35,31,32)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,202.327,105.871)">
            <Path
              d="M0,-1.603C0.011,-2.284 -0.159,-2.842 -0.51,-3.28C-0.861,-3.717 -1.344,-3.936 -1.96,-3.936C-2.306,-3.936 -2.611,-3.868 -2.876,-3.733C-3.14,-3.598 -3.364,-3.423 -3.547,-3.207C-3.732,-2.991 -3.874,-2.743 -3.977,-2.462C-4.08,-2.181 -4.147,-1.895 -4.179,-1.603L0,-1.603ZM-5.783,-0.777C-5.783,-1.522 -5.675,-2.173 -5.459,-2.729C-5.243,-3.285 -4.957,-3.747 -4.601,-4.114C-4.244,-4.481 -3.834,-4.756 -3.369,-4.94C-2.905,-5.124 -2.43,-5.216 -1.944,-5.216C-0.81,-5.216 0.06,-4.862 0.665,-4.155C1.269,-3.447 1.572,-2.37 1.572,-0.923L1.572,-0.671C1.572,-0.569 1.566,-0.475 1.556,-0.388L-4.212,-0.388C-4.147,0.487 -3.894,1.151 -3.45,1.605C-3.008,2.058 -2.316,2.285 -1.377,2.285C-0.848,2.285 -0.402,2.239 -0.04,2.147C0.321,2.056 0.594,1.966 0.778,1.88L0.988,3.144C0.805,3.241 0.483,3.343 0.024,3.451C-0.435,3.56 -0.956,3.613 -1.539,3.613C-2.273,3.613 -2.908,3.502 -3.442,3.281C-3.977,3.06 -4.417,2.755 -4.763,2.366C-5.108,1.977 -5.365,1.516 -5.532,0.981C-5.7,0.446 -5.783,-0.14 -5.783,-0.777"
              style={{
                fill: "rgb(35,31,32)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,211.253,111.024)">
            <Path
              d="M0,-8.635C-0.151,-8.678 -0.354,-8.718 -0.607,-8.757C-0.861,-8.794 -1.205,-8.813 -1.636,-8.813C-2.446,-8.813 -3.07,-8.548 -3.507,-8.019C-3.945,-7.49 -4.164,-6.788 -4.164,-5.913C-4.164,-5.427 -4.102,-5.011 -3.977,-4.666C-3.853,-4.32 -3.686,-4.034 -3.475,-3.807C-3.264,-3.58 -3.021,-3.413 -2.746,-3.305C-2.471,-3.197 -2.187,-3.143 -1.896,-3.143C-1.496,-3.143 -1.129,-3.2 -0.794,-3.313C-0.459,-3.427 -0.194,-3.559 0,-3.71L0,-8.635ZM0.016,-2.382C-0.113,-2.295 -0.365,-2.184 -0.737,-2.05C-1.11,-1.914 -1.544,-1.847 -2.041,-1.847C-2.549,-1.847 -3.027,-1.928 -3.475,-2.09C-3.923,-2.252 -4.315,-2.503 -4.65,-2.843C-4.984,-3.184 -5.249,-3.607 -5.443,-4.115C-5.638,-4.623 -5.735,-5.227 -5.735,-5.929C-5.735,-6.545 -5.643,-7.109 -5.46,-7.622C-5.276,-8.135 -5.009,-8.578 -4.658,-8.951C-4.307,-9.323 -3.878,-9.615 -3.37,-9.826C-2.862,-10.036 -2.29,-10.141 -1.652,-10.141C-0.951,-10.141 -0.338,-10.09 0.186,-9.988C0.71,-9.885 1.15,-9.79 1.506,-9.704L1.506,-2.187C1.506,-0.891 1.172,0.048 0.502,0.632C-0.167,1.215 -1.183,1.506 -2.544,1.506C-3.073,1.506 -3.572,1.463 -4.042,1.377C-4.512,1.29 -4.919,1.188 -5.265,1.069L-4.99,-0.243C-4.687,-0.125 -4.317,-0.019 -3.88,0.073C-3.442,0.164 -2.986,0.21 -2.511,0.21C-1.615,0.21 -0.97,0.032 -0.575,-0.324C-0.181,-0.681 0.016,-1.248 0.016,-2.025L0.016,-2.382Z"
              style={{
                fill: "rgb(35,31,32)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,221.134,105.077)">
            <Path
              d="M0,0.001C0,-0.949 -0.213,-1.703 -0.64,-2.259C-1.066,-2.815 -1.647,-3.093 -2.381,-3.093C-3.116,-3.093 -3.696,-2.815 -4.123,-2.259C-4.549,-1.703 -4.763,-0.949 -4.763,0.001C-4.763,0.951 -4.549,1.705 -4.123,2.261C-3.696,2.817 -3.116,3.095 -2.381,3.095C-1.647,3.095 -1.066,2.817 -0.64,2.261C-0.213,1.705 0,0.951 0,0.001M1.572,0.001C1.572,0.67 1.475,1.275 1.28,1.815C1.085,2.355 0.813,2.82 0.462,3.208C0.111,3.597 -0.308,3.897 -0.794,4.107C-1.28,4.318 -1.809,4.423 -2.381,4.423C-2.954,4.423 -3.483,4.318 -3.969,4.107C-4.455,3.897 -4.874,3.597 -5.224,3.208C-5.575,2.82 -5.848,2.355 -6.042,1.815C-6.237,1.275 -6.334,0.67 -6.334,0.001C-6.334,-0.658 -6.237,-1.26 -6.042,-1.806C-5.848,-2.351 -5.575,-2.818 -5.224,-3.207C-4.874,-3.596 -4.455,-3.895 -3.969,-4.106C-3.483,-4.316 -2.954,-4.422 -2.381,-4.422C-1.809,-4.422 -1.28,-4.316 -0.794,-4.106C-0.308,-3.895 0.111,-3.596 0.462,-3.207C0.813,-2.818 1.085,-2.351 1.28,-1.806C1.475,-1.26 1.572,-0.658 1.572,0.001"
              style={{
                fill: "rgb(35,31,32)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,52.0326,49.6082)">
            <Path
              d="M0,17.38C-1.162,17.38 -2.16,17.212 -2.995,16.877C-3.83,16.542 -4.521,16.071 -5.069,15.465C-5.618,14.86 -6.022,14.148 -6.284,13.33C-6.546,12.511 -6.677,11.611 -6.677,10.629L-6.677,0L-3.56,0L-3.56,10.335C-3.56,11.104 -3.474,11.763 -3.302,12.311C-3.13,12.859 -2.884,13.305 -2.565,13.649C-2.246,13.992 -1.87,14.246 -1.436,14.41C-1.002,14.573 -0.516,14.655 0.024,14.655C0.564,14.655 1.056,14.573 1.498,14.41C1.939,14.246 2.32,13.992 2.639,13.649C2.958,13.305 3.204,12.859 3.375,12.311C3.547,11.763 3.633,11.104 3.633,10.335L3.633,0L6.75,0L6.75,10.629C6.75,11.611 6.616,12.511 6.346,13.33C6.076,14.148 5.666,14.86 5.118,15.465C4.57,16.071 3.87,16.542 3.02,16.877C2.168,17.212 1.162,17.38 0,17.38"
              style={{
                fill: "rgb(35,31,32)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,62.4408,66.2764)">
            <Path
              d="M0,-11.734C0.573,-11.897 1.317,-12.053 2.234,-12.2C3.15,-12.348 4.165,-12.421 5.278,-12.421C6.325,-12.421 7.201,-12.278 7.904,-11.992C8.608,-11.705 9.169,-11.304 9.586,-10.789C10.004,-10.274 10.298,-9.651 10.47,-8.923C10.642,-8.195 10.728,-7.397 10.728,-6.53L10.728,0.687L7.757,0.687L7.757,-6.063C7.757,-6.751 7.712,-7.336 7.622,-7.819C7.532,-8.301 7.385,-8.694 7.18,-8.997C6.976,-9.3 6.697,-9.52 6.346,-9.66C5.994,-9.798 5.564,-9.868 5.057,-9.868C4.68,-9.868 4.288,-9.844 3.879,-9.795C3.469,-9.746 3.167,-9.705 2.97,-9.672L2.97,0.687L0,0.687L0,-11.734Z"
              style={{
                fill: "rgb(35,31,32)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,0,-43.241)">
            <Path
              d="M79.649,110.205L76.678,110.205L76.678,97.367L79.649,97.367L79.649,110.205ZM79.968,93.61C79.968,94.167 79.788,94.609 79.428,94.936C79.068,95.263 78.642,95.427 78.151,95.427C77.644,95.427 77.21,95.263 76.85,94.936C76.49,94.609 76.31,94.167 76.31,93.61C76.31,93.038 76.49,92.588 76.85,92.26C77.21,91.933 77.644,91.769 78.151,91.769C78.642,91.769 79.068,91.933 79.428,92.26C79.788,92.588 79.968,93.038 79.968,93.61"
              style={{
                fill: "rgb(35,31,32)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,87.7983,55.9177)">
            <Path
              d="M0,8.714C0.916,8.714 1.611,8.665 2.086,8.567L2.086,5.277C1.923,5.228 1.685,5.179 1.374,5.13C1.063,5.081 0.72,5.056 0.343,5.056C0.016,5.056 -0.316,5.081 -0.651,5.13C-0.986,5.179 -1.289,5.269 -1.559,5.4C-1.829,5.531 -2.046,5.715 -2.21,5.952C-2.374,6.19 -2.455,6.488 -2.455,6.848C-2.455,7.552 -2.234,8.039 -1.792,8.309C-1.35,8.579 -0.753,8.714 0,8.714M-0.246,-2.357C0.736,-2.357 1.562,-2.234 2.234,-1.989C2.904,-1.744 3.44,-1.4 3.842,-0.958C4.242,-0.516 4.529,0.02 4.701,0.65C4.872,1.28 4.958,1.972 4.958,2.724L4.958,10.506C4.5,10.604 3.809,10.723 2.884,10.862C1.959,11.001 0.916,11.071 -0.246,11.071C-1.015,11.071 -1.719,10.997 -2.357,10.85C-2.995,10.702 -3.54,10.465 -3.989,10.138C-4.439,9.811 -4.792,9.385 -5.045,8.862C-5.299,8.338 -5.425,7.692 -5.425,6.922C-5.425,6.186 -5.282,5.564 -4.996,5.056C-4.71,4.549 -4.321,4.136 -3.83,3.817C-3.339,3.498 -2.771,3.269 -2.124,3.13C-1.477,2.99 -0.802,2.921 -0.098,2.921C0.229,2.921 0.572,2.942 0.933,2.982C1.292,3.023 1.677,3.092 2.086,3.191L2.086,2.7C2.086,2.356 2.045,2.029 1.964,1.718C1.881,1.407 1.738,1.133 1.534,0.896C1.329,0.658 1.059,0.474 0.724,0.343C0.388,0.213 -0.033,0.147 -0.54,0.147C-1.228,0.147 -1.858,0.196 -2.431,0.294C-3.004,0.392 -3.47,0.507 -3.83,0.638L-4.198,-1.768C-3.822,-1.899 -3.273,-2.03 -2.553,-2.161C-1.833,-2.291 -1.064,-2.357 -0.246,-2.357"
              style={{
                fill: "rgb(35,31,32)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,101.962,49.9518)">
            <Path
              d="M0,17.012L0,0L10.924,0L10.924,2.627L3.093,2.627L3.093,6.824L10.064,6.824L10.064,9.402L3.093,9.402L3.093,14.386L11.513,14.386L11.513,17.012L0,17.012Z"
              style={{
                fill: "rgb(35,31,32)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,126.166,54.5422)">
            <Path
              d="M0,11.759C-0.573,11.906 -1.317,12.058 -2.234,12.213C-3.151,12.369 -4.157,12.446 -5.253,12.446C-6.284,12.446 -7.148,12.299 -7.843,12.004C-8.539,11.71 -9.095,11.301 -9.512,10.777C-9.93,10.253 -10.229,9.627 -10.409,8.899C-10.589,8.171 -10.679,7.373 -10.679,6.506L-10.679,-0.687L-7.708,-0.687L-7.708,6.039C-7.708,7.414 -7.508,8.396 -7.107,8.985C-6.706,9.574 -6.006,9.868 -5.008,9.868C-4.648,9.868 -4.267,9.852 -3.866,9.82C-3.465,9.787 -3.167,9.746 -2.97,9.697L-2.97,-0.687L0,-0.687L0,11.759Z"
              style={{
                fill: "rgb(35,31,32)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,137.115,64.0177)">
            <Path
              d="M0,-7.217C-0.246,-7.299 -0.585,-7.385 -1.019,-7.474C-1.453,-7.564 -1.956,-7.61 -2.528,-7.61C-2.856,-7.61 -3.204,-7.576 -3.572,-7.512C-3.94,-7.446 -4.198,-7.389 -4.345,-7.34L-4.345,2.946L-7.316,2.946L-7.316,-9.279C-6.743,-9.491 -6.026,-9.692 -5.167,-9.88C-4.308,-10.068 -3.355,-10.163 -2.308,-10.163C-2.111,-10.163 -1.882,-10.15 -1.62,-10.126C-1.358,-10.101 -1.097,-10.068 -0.835,-10.028C-0.573,-9.987 -0.319,-9.937 -0.074,-9.88C0.172,-9.823 0.368,-9.77 0.516,-9.721L0,-7.217Z"
              style={{
                fill: "rgb(35,31,32)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,148.112,60.2613)">
            <Path
              d="M0,-0.048C0,-1.325 -0.274,-2.335 -0.822,-3.08C-1.371,-3.824 -2.136,-4.197 -3.118,-4.197C-4.1,-4.197 -4.865,-3.824 -5.413,-3.08C-5.961,-2.335 -6.235,-1.325 -6.235,-0.048C-6.235,1.245 -5.961,2.268 -5.413,3.02C-4.865,3.773 -4.1,4.149 -3.118,4.149C-2.136,4.149 -1.371,3.773 -0.822,3.02C-0.274,2.268 0,1.245 0,-0.048M3.044,-0.048C3.044,0.967 2.896,1.891 2.602,2.726C2.308,3.56 1.89,4.272 1.35,4.861C0.81,5.45 0.16,5.909 -0.602,6.236C-1.362,6.564 -2.201,6.727 -3.118,6.727C-4.034,6.727 -4.869,6.564 -5.622,6.236C-6.374,5.909 -7.021,5.45 -7.561,4.861C-8.101,4.272 -8.522,3.56 -8.825,2.726C-9.128,1.891 -9.279,0.967 -9.279,-0.048C-9.279,-1.063 -9.128,-1.983 -8.825,-2.81C-8.522,-3.636 -8.097,-4.344 -7.548,-4.933C-7.001,-5.523 -6.35,-5.977 -5.597,-6.296C-4.844,-6.615 -4.018,-6.775 -3.118,-6.775C-2.218,-6.775 -1.387,-6.615 -0.626,-6.296C0.135,-5.977 0.786,-5.523 1.326,-4.933C1.866,-4.344 2.287,-3.636 2.59,-2.81C2.893,-1.983 3.044,-1.063 3.044,-0.048"
              style={{
                fill: "rgb(35,31,32)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,162.595,64.7539)">
            <Path
              d="M0,-4.148C0,-5.474 -0.299,-6.504 -0.896,-7.241C-1.494,-7.978 -2.455,-8.346 -3.78,-8.346C-4.059,-8.346 -4.349,-8.334 -4.652,-8.309C-4.955,-8.284 -5.253,-8.231 -5.548,-8.149L-5.548,-0.81C-5.286,-0.629 -4.938,-0.461 -4.504,-0.306C-4.071,-0.151 -3.608,-0.073 -3.118,-0.073C-2.037,-0.073 -1.248,-0.442 -0.748,-1.178C-0.249,-1.914 0,-2.904 0,-4.148M3.044,-4.197C3.044,-3.215 2.917,-2.315 2.664,-1.497C2.41,-0.678 2.046,0.025 1.571,0.614C1.096,1.204 0.504,1.662 -0.208,1.989C-0.92,2.316 -1.726,2.48 -2.626,2.48C-3.232,2.48 -3.788,2.406 -4.296,2.259C-4.803,2.112 -5.221,1.948 -5.548,1.768L-5.548,6.751L-8.518,6.751L-8.518,-10.211C-7.913,-10.375 -7.168,-10.53 -6.284,-10.678C-5.4,-10.825 -4.468,-10.899 -3.486,-10.899C-2.471,-10.899 -1.563,-10.743 -0.761,-10.432C0.041,-10.121 0.724,-9.675 1.289,-9.094C1.854,-8.513 2.287,-7.81 2.59,-6.983C2.893,-6.157 3.044,-5.228 3.044,-4.197"
              style={{
                fill: "rgb(35,31,32)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,176.464,61.5147)">
            <Path
              d="M0,-2.529C0,-2.938 -0.057,-3.327 -0.171,-3.695C-0.286,-4.063 -0.454,-4.383 -0.675,-4.653C-0.896,-4.923 -1.166,-5.135 -1.485,-5.291C-1.804,-5.446 -2.185,-5.524 -2.626,-5.524C-3.085,-5.524 -3.485,-5.438 -3.829,-5.266C-4.173,-5.094 -4.464,-4.869 -4.701,-4.591C-4.938,-4.313 -5.122,-3.993 -5.253,-3.634C-5.384,-3.274 -5.474,-2.905 -5.523,-2.529L0,-2.529ZM-8.616,-1.203C-8.616,-2.333 -8.449,-3.322 -8.113,-4.174C-7.778,-5.025 -7.332,-5.733 -6.775,-6.297C-6.219,-6.862 -5.581,-7.287 -4.86,-7.574C-4.14,-7.86 -3.404,-8.003 -2.651,-8.003C-0.883,-8.003 0.495,-7.455 1.485,-6.359C2.476,-5.262 2.971,-3.625 2.971,-1.449C2.971,-1.285 2.966,-1.101 2.959,-0.897C2.95,-0.692 2.938,-0.508 2.921,-0.344L-5.548,-0.344C-5.466,0.687 -5.102,1.485 -4.455,2.049C-3.809,2.614 -2.872,2.896 -1.645,2.896C-0.924,2.896 -0.266,2.831 0.332,2.7C0.929,2.569 1.399,2.429 1.743,2.283L2.136,4.713C1.972,4.795 1.747,4.881 1.461,4.97C1.174,5.06 0.847,5.142 0.479,5.216C0.111,5.289 -0.286,5.351 -0.711,5.4C-1.137,5.449 -1.571,5.474 -2.013,5.474C-3.142,5.474 -4.124,5.306 -4.958,4.97C-5.793,4.635 -6.48,4.169 -7.02,3.571C-7.561,2.974 -7.962,2.27 -8.223,1.46C-8.485,0.65 -8.616,-0.238 -8.616,-1.203"
              style={{
                fill: "rgb(35,31,32)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,185.719,69.6885)">
            <Path
              d="M0,-19.319C0,-18.763 -0.18,-18.321 -0.54,-17.994C-0.9,-17.666 -1.326,-17.503 -1.816,-17.503C-2.324,-17.503 -2.758,-17.666 -3.118,-17.994C-3.478,-18.321 -3.658,-18.763 -3.658,-19.319C-3.658,-19.892 -3.478,-20.342 -3.118,-20.67C-2.758,-20.997 -2.324,-21.16 -1.816,-21.16C-1.326,-21.16 -0.9,-20.997 -0.54,-20.67C-0.18,-20.342 0,-19.892 0,-19.319M-4.934,1.841C-5.147,1.841 -5.445,1.821 -5.83,1.78C-6.214,1.739 -6.554,1.661 -6.849,1.547L-6.456,-0.883C-6.096,-0.769 -5.662,-0.712 -5.155,-0.712C-4.468,-0.712 -3.985,-0.912 -3.707,-1.313C-3.429,-1.714 -3.29,-2.316 -3.29,-3.117L-3.29,-15.563L-0.319,-15.563L-0.319,-3.068C-0.319,-1.399 -0.716,-0.164 -1.51,0.639C-2.304,1.44 -3.445,1.841 -4.934,1.841"
              style={{
                fill: "rgb(35,31,32)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,192.249,55.9666)">
            <Path
              d="M0,8.567C0.786,8.567 1.358,8.473 1.718,8.285C2.078,8.097 2.258,7.773 2.258,7.315C2.258,6.89 2.066,6.538 1.682,6.259C1.297,5.982 0.663,5.679 -0.221,5.351C-0.761,5.155 -1.256,4.946 -1.706,4.725C-2.156,4.504 -2.545,4.247 -2.872,3.952C-3.2,3.657 -3.458,3.301 -3.646,2.884C-3.833,2.467 -3.928,1.956 -3.928,1.35C-3.928,0.171 -3.494,-0.757 -2.626,-1.436C-1.759,-2.115 -0.581,-2.455 0.908,-2.455C1.661,-2.455 2.381,-2.385 3.068,-2.247C3.756,-2.107 4.271,-1.972 4.615,-1.841L4.075,0.564C3.748,0.417 3.33,0.282 2.823,0.159C2.316,0.036 1.727,-0.025 1.056,-0.025C0.45,-0.025 -0.041,0.078 -0.417,0.282C-0.794,0.487 -0.982,0.802 -0.982,1.227C-0.982,1.44 -0.945,1.628 -0.872,1.792C-0.798,1.956 -0.671,2.107 -0.491,2.246C-0.311,2.385 -0.074,2.524 0.221,2.663C0.516,2.802 0.876,2.945 1.301,3.093C2.004,3.355 2.602,3.612 3.093,3.866C3.584,4.12 3.989,4.406 4.308,4.725C4.628,5.044 4.86,5.409 5.008,5.818C5.155,6.227 5.229,6.718 5.229,7.291C5.229,8.518 4.775,9.447 3.866,10.077C2.958,10.707 1.661,11.022 -0.024,11.022C-1.154,11.022 -2.062,10.928 -2.749,10.739C-3.437,10.551 -3.919,10.4 -4.198,10.285L-3.682,7.806C-3.24,7.986 -2.712,8.158 -2.099,8.321C-1.485,8.485 -0.786,8.567 0,8.567"
              style={{
                fill: "rgb(35,31,32)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,203.074,57.7827)">
            <Path
              d="M0,1.35C0.377,0.957 0.777,0.537 1.203,0.086C1.629,-0.364 2.046,-0.814 2.455,-1.264C2.864,-1.714 3.253,-2.148 3.621,-2.565C3.989,-2.982 4.304,-3.346 4.566,-3.657L8.077,-3.657C7.258,-2.741 6.395,-1.792 5.487,-0.81C4.578,0.172 3.666,1.129 2.75,2.062C3.241,2.472 3.756,2.967 4.296,3.547C4.836,4.128 5.36,4.747 5.867,5.401C6.374,6.056 6.849,6.71 7.291,7.365C7.733,8.019 8.101,8.625 8.396,9.181L4.959,9.181C4.664,8.674 4.324,8.142 3.94,7.585C3.555,7.029 3.142,6.489 2.701,5.965C2.259,5.442 1.808,4.947 1.35,4.48C0.892,4.014 0.442,3.617 0,3.29L0,9.181L-2.97,9.181L-2.97,-7.34L0,-7.831L0,1.35Z"
              style={{
                fill: "rgb(35,31,32)",
                fillRule: "nonzero",
              }}
            />
          </G>
          <G transform="matrix(1,0,0,1,217.999,55.9177)">
            <Path
              d="M0,8.714C0.916,8.714 1.611,8.665 2.087,8.567L2.087,5.277C1.923,5.228 1.685,5.179 1.374,5.13C1.063,5.081 0.72,5.056 0.343,5.056C0.016,5.056 -0.315,5.081 -0.651,5.13C-0.986,5.179 -1.289,5.269 -1.559,5.4C-1.829,5.531 -2.046,5.715 -2.209,5.952C-2.374,6.19 -2.455,6.488 -2.455,6.848C-2.455,7.552 -2.234,8.039 -1.792,8.309C-1.35,8.579 -0.753,8.714 0,8.714M-0.246,-2.357C0.736,-2.357 1.562,-2.234 2.234,-1.989C2.904,-1.744 3.44,-1.4 3.842,-0.958C4.242,-0.516 4.529,0.02 4.701,0.65C4.873,1.28 4.958,1.972 4.958,2.724L4.958,10.506C4.5,10.604 3.809,10.723 2.884,10.862C1.959,11.001 0.916,11.071 -0.246,11.071C-1.015,11.071 -1.719,10.997 -2.357,10.85C-2.995,10.702 -3.54,10.465 -3.989,10.138C-4.439,9.811 -4.792,9.385 -5.045,8.862C-5.299,8.338 -5.425,7.692 -5.425,6.922C-5.425,6.186 -5.282,5.564 -4.996,5.056C-4.709,4.549 -4.321,4.136 -3.83,3.817C-3.339,3.498 -2.771,3.269 -2.124,3.13C-1.477,2.99 -0.802,2.921 -0.098,2.921C0.229,2.921 0.572,2.942 0.933,2.982C1.292,3.023 1.677,3.092 2.087,3.191L2.087,2.7C2.087,2.356 2.045,2.029 1.964,1.718C1.881,1.407 1.738,1.133 1.534,0.896C1.329,0.658 1.059,0.474 0.724,0.343C0.388,0.213 -0.033,0.147 -0.54,0.147C-1.228,0.147 -1.858,0.196 -2.431,0.294C-3.003,0.392 -3.47,0.507 -3.83,0.638L-4.198,-1.768C-3.822,-1.899 -3.273,-2.03 -2.553,-2.161C-1.833,-2.291 -1.064,-2.357 -0.246,-2.357"
              style={{
                fill: "rgb(35,31,32)",
                fillRule: "nonzero",
              }}
            />
          </G>
        </G>
      </G>
    </Svg>
  )
}

export default SvgComponent
